import Raiox from '@/components/medico/raiox/index.vue'
import PdfExam from '@/components/medico/pdfExam/index.vue'
import feedback from '@/components/medico/feedback/index.vue'
import Analyst from '@/components/medico/analyst/index.vue'
import CONSTANTS from '@/shared/constants/constants'
import MedicalReportMixin from '@/shared/mixins/medical-report.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'medico',
  components: {
    Raiox,
    PdfExam,
    feedback,
    Analyst
  },
  mixins: [
    MedicalReportMixin,
    AuthMixin
  ],
  data () {
    return {
      exam: null,
      SPECIALITY: CONSTANTS.SPECIALITY,
      STATUS: CONSTANTS.STATUS,
      isAria: false,
      isOit: false,
      specialtiesAria: [],
      user: {},
      role: ''
    }
  },
  computed: {
    isSingleSession () {
      const isSingleSession = this.getIsSingleSession()

      if (isSingleSession && !this.exam) {
        this.getExamQueue()
      }
      return isSingleSession
    },
    isAnalyst () {
      return this.role === CONSTANTS.ROLES.ANALYST
    }
  },
  mounted () {
    this.$root.$on('refreshTimeout', this.setPrioritasUpdateTime)
    this.specialtiesAria.push(
      this.SPECIALITY.RAIOX,
      this.SPECIALITY.TOMOGRAFIA,
      this.SPECIALITY.RESSONANCIA,
      this.SPECIALITY.MAMOGRAFIA,
      this.SPECIALITY.DENSITOMETRIA
    )
    this.user = this.getUser()
    this.role = this.getFirstRole()

    if (this.isSingleSession === null) {
      this.$root.$emit('showLoading')
      this.startVerifySession()
    }
  },
  destroyed () {
    document.removeEventListener('visibilitychange', () => this.handleVisibilityChange(), false)
  },
  methods: {
    ...mapGetters([
      'getIsSingleSession'
    ]),
    ...mapActions([
      'setDontReload',
      'setSingleSession'
    ]),
    startVerifySession () {
      setTimeout(() => {
        if (this.isSingleSession === null) {
          this.setSingleSession(true)
        }
      }, 2000)
    },
    logout () {
      this.logoutUser()
    },
    refresh () {
      this.setDontReload(true)
      this.setSingleSession(true)
      this.$socket.emit('reload')
    },
    async getExamQueue () {
      this.$root.$emit('showLoading')
      try {
        const storedValidation = sessionStorage.getItem('certificateValidation')

        if (storedValidation) {
          const parsedValidation = JSON.parse(storedValidation)
          if (parsedValidation.token && parsedValidation.tokenExpiration) {
            const tokenExpiration = new Date(parsedValidation.tokenExpiration)

            if (new Date() >= tokenExpiration) {
              await this.getValidateCertificate(true)
            }
          } else {
            await this.getValidateCertificate(true)
          }
        } else {
          await this.getValidateCertificate(true)
        }
        const res = await this.getQueueFirstItem()
        if (res.status === 204) {
          const data = {
            hasMoreExams: false,
            type: 'dontHaveExams'
          }
          return this.showFeedback(data)
        }
        this.exam = res.data.data
        this.isOit = this.exam.specialityId === CONSTANTS.SPECIALITY.RAIOXOIT
        this.isAria = this.specialtiesAria.includes(this.exam.specialityId) && this.exam.pixeonUid != null

        if (!this.exam.masks) {
          this.exam.masks = []
        }

        const retificationStatus = [this.STATUS.AGUARDANDO_RETIFICACAO, this.STATUS.RETIFICANDO]
        if (retificationStatus.includes(this.exam.status)) {
          this.exam.reportStatus = this.exam.isAlterado ? CONSTANTS.REPORT_STATUS.ALTERADO : CONSTANTS.REPORT_STATUS.NORMAL
        }
        if (this.exam.hasInformation || this.exam.observations.length && !this.isOit) {
          const data = {
            hasMoreExams: true,
            type: 'warnMessage'
          }
          this.showFeedback(data)
        }
        if (this.exam.retification && Object.keys(this.exam.retification).length) {
          const data = {
            hasMoreExams: true,
            type: 'rectificationMessage'
          }
          this.showFeedback(data)
        }
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    nextExam () {
      this.exam = {}
      this.getExamQueue()
    },
    showFeedback (data) {
      this.$refs.feedback.showFeedback(data.hasMoreExams, data.type)
    },
    setPrioritasUpdateTime (data) {
      this.exam.prioritasUpdateTime = data
    }
  }
}

import SpecialtyMixin from '@/shared/mixins/specialty.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import RadioExamsMixin from '@/shared/mixins/radio-report.mixin'
import DateMixin from '@/shared/mixins/date.mixin'
import RadioHeader from '@/components/medico/radioHeader/index.vue'
import RepetirExame from '@/components/medico/repetir-exame/index.vue'
import Laudar from '@/components/medico/laudar-exame/index.vue'
import RadioReportPopUp from '@/components/radio-popup/index.vue'

export default {
  name: 'RadioReport',
  mixins: [SpecialtyMixin, AuthMixin, RadioExamsMixin, DateMixin],
  components: {
    RadioHeader,
    RepetirExame,
    Laudar,
    RadioReportPopUp
  },
  data() {
    return {
      reRenderKey: 0,
      reportText: '',
      examSelected: null,
      items: [],
      filteredItems: [],
      query: '',
      paginatedItems: [],
      viewingObservation: false,
      currentReport: null,
      currentObservation: {},
      isLoading: false,
      interval: null,
      selectedMasks: [],
      isReadOnly: false,
      repeat: false,
      reportStatus: '',
      abnormal: null,
      masks: [],
      showMasks: false,
      isAbnormal: false,
      specialties: [],
      selectedSpecialty: null,
      showSpecialtyDropdown: false,
      description: '',
      pinnedExam: null,
      showDialog: 0,
      nextExamId: null,
      isMounted: false,
      hasLoaded: false,
      lastUpdated: new Date(),
      isTimerActive: false,
      examDetails: '',
      hasUrgencyAlert: false,
      lastReportText: '',
      notifications: [],
      showEditButton: true,
      isCollapsed: true,
      isTextareaDisabled: false,
      showMaintainButton: false,
      showSubmitButton: true,
      isMaintainClicked: false,
      isEditClicked: false
    }
  },
  async mounted() {
    await this.load()
    await this.fetchMasks()
    this.isMounted = true
  },
  watch: {
    items: 'filterItems',
    query: 'filterItems',
    reportText: {
      handler(newText, oldText) {
        if (newText !== oldText) {
          this.checkAndDeselectMasks()
        }
      }
    },
    isAbnormal: {
      handler(newValue) {
        this.fetchMasks()
        this.autoSelectSpecialtyIfOnlyOne()
      }
    },
    examDetails(newVal) {
      if (newVal && newVal.retification) {
        this.showEditButton = true
        this.showMaintainButton = true
        this.showSubmitButton = false
      } else {
        this.showEditButton = false
        this.showMaintainButton = false
        this.showSubmitButton = true
      }
    },
    async examSelected(newExamId) {
      if (newExamId) {
        await this.fetchExamDetails(newExamId)
        this.isEditClicked = false
      }
      this.$nextTick(() => {
        if (this.$refs.table) {
          this.$refs.table.sortBy = 'patientName'
          this.$refs.table.sortOrder = 'asc'
        }
      })
    },
    repeat(newValue) {
      if (newValue) {
        this.reportStatus = ''
      }
    }
  },
  computed: {
    sortedItems: {
      get() {
        return this.filteredItems
      },
      set(value) {
        this.filteredItems = value
      }
    },

    shouldShowSpecialtyDropdown() {
      return (
        (!this.filteredSpecialties || this.filteredSpecialties.length !== 1) &&
        !this.repeat &&
        this.reportStatus !== 'alterado' && this.reportStatus !== 'normal' &&
        this.reportStatus
      )
    },
    filteredSpecialties() {
      if (!this.masks.length) return []

      // When normal is selected (isAbnormal = false), show masks where isChanged = false
      // When abnormal is selected (isAbnormal = true), show masks where isChanged = true
      const specialtiesWithMatchingMasks = this.masks
        .filter((mask) => {
          if (this.isAbnormal) {
            return Boolean(mask.isChanged) === true
          } else {
            return Boolean(mask.isChanged) === false
          }
        })
        .map((mask) => mask.specialityId)
      return [...new Set(specialtiesWithMatchingMasks)]
    },
    filteredMasks() {
      if (!this.selectedSpecialty) return []
      return this.masks.filter((mask) => {
        // First, filter by specialty
        const hasMatchingSpecialty = mask.specialityId === this.selectedSpecialty

        // Then, apply the condition based on isAbnormal:
        // When normal is selected (isAbnormal = false), show masks where isChanged = false
        // When abnormal is selected (isAbnormal = true), show masks where isChanged = true
        const hasMatchingStatus = this.isAbnormal ? Boolean(mask.isChanged) === true : Boolean(mask.isChanged) === false

        return hasMatchingSpecialty && hasMatchingStatus
      })
    },
    currentExam() {
      return this.items.find((item) => item.examId === this.examSelected)
    },
    formattedLastUpdated() {
      return this.$moment(this.lastUpdated).format('DD/MM/YYYY HH:mm:ss')
    },
    formattedCreationDate() {
      return this.$moment(this.examDetails.creationDate).format('DD/MM/YYYY HH:mm')
    }
  },
  methods: {
    getSpecialtyName(specialtyId) {
      const specialty = this.items.find((item) => item.specialityId === specialtyId)
      if (specialty && specialty.specialtyName) {
        return specialty.specialtyName
      }

      if (this.examDetails && this.examDetails.specialityId === specialtyId && this.examDetails.specialtyName) {
        return this.examDetails.specialtyName
      }

      const specialtyMap = {
        19: 'ELETROCARDIOGRAMA',
        25: 'RX',
        53: 'RX-OIT',
        20: 'ELETROENCEFALOGRAMA',
        22: 'ESPIROMETRIA',
        87: 'TOMOGRAFIA',
        109: 'RESSONANCIA',
        110: 'MAMOGRAFIA',
        113: 'DENSITOMETRIA',
        16: 'ACUIDADE',
        24: 'MAPA',
        99: 'ESFORCO_CARDIOPULMONAR',
        75: 'TESTE_ERGOMETRICO',
        160: 'RM-PUNHO ESQUERDO'
      }

      return specialtyMap[specialtyId] || `Specialty ${specialtyId}`
    },

    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed
    },
    clearForm() {
      this.reportStatus = ''
      this.repeat = false
      this.reportText = ''
      this.clearReportFromStorage(this.examSelected)
      this.showEditButton = false
      this.showMasks = false
      this.isAbnormal = false
      this.selectedMasks = []
      this.selectedSpecialty = null
      this.showSpecialtyDropdown = false
    },
    async fetchExamDetails(examId) {
      try {
        const details = await this.getDetails(examId)

        if (!details) {
          return
        }

        this.examDetails = {
          ...details,
          specialityId: details.specialityId || details.specialtyId || (details.specialty && details.specialty.id)
        }

        if (this.examDetails.retification) {
          this.reportStatus = this.examDetails.isAlterado ? 'alterado' : 'normal'
          this.isTextareaDisabled = true
          this.reportText = this.examDetails.description
          this.isAbnormal = this.examDetails.isAlterado
          this.showMasks = true
        } else {
          this.isTextareaDisabled = false
        }

        if (this.examDetails.specialityId) {
          await this.fetchMasks()
        }
      } catch (error) {}
    },
    enableTextarea() {
      if (this.examDetails.retification) {
        this.isTextareaDisabled = false
        this.showEditButton = false
        this.showMaintainButton = false
        this.showSubmitButton = true
        this.isEditClicked = true
        this.isMaintainClicked = false
      }
    },
    handleMaintain() {
      if (this.examDetails.retification) {
        this.isTextareaDisabled = true
        this.showMaintainButton = false
        this.showEditButton = false
        this.showSubmitButton = true
        this.isMaintainClicked = true
        this.isEditClicked = false
      }
    },
    async fetchMasks() {
      try {
        const specialityId = this.examDetails?.specialityId
        if (!specialityId) {
          return
        }

        const response = await this.getMaskOptions(specialityId)

        const allMasks = response.data.map((mask) => ({
          id: mask.id,
          title: mask.name, // Used in template display
          content: mask.text, // Used in tooltip
          isActive: mask.isActive,
          specialityId: mask.specialityId,
          languageId: mask.languageId,
          isChanged: mask.isChanged
        }))

        if (allMasks.length > 0) {
          // Se houver máscaras disponíveis, verifica se há apenas uma especialidade
          this.autoSelectSpecialtyIfOnlyOne()
        }

        this.masks = allMasks

        const uniqueSpecialties = [...new Set(allMasks.map((mask) => mask.specialityId))]
        this.specialties = uniqueSpecialties

        // If this is a rectification exam, auto-select the specialty
        if (this.examDetails.retification && this.examDetails.specialityId) {
          this.selectedSpecialty = this.examDetails.specialityId
        }
      } catch (error) {
        this.masks = []
      }
    },

    selectSpecialty(specialtyId) {
      this.selectedSpecialty = specialtyId
      this.showSpecialtyDropdown = false
    },

    toggleSpecialtyDropdown() {
      if (
        this.repeat ||
        this.reportStatus === 'alterado' ||
        (this.filteredSpecialties && this.filteredSpecialties.length === 1)
      ) {
        this.showSpecialtyDropdown = false
        return
      }
      this.showSpecialtyDropdown = !this.showSpecialtyDropdown
      if (this.showSpecialtyDropdown) {
        this.showMasks = true
      }
    },

    handleStatusChange(status) {
      this.reportStatus = status
      this.isAbnormal = status === 'alterado'
      if (status === 'alterado') {
        this.showSpecialtyDropdown = false
      }
      this.selectedSpecialty = null
      this.autoSelectSpecialtyIfOnlyOne()
    },
    handleMaskSelection(mask) {
      const maskIndex = this.selectedMasks.findIndex((m) => m.id === mask.id)

      if (maskIndex === -1) {
        this.selectedMasks.push(mask)
        this.reportText = this.reportText ? `${this.reportText}\n${mask.content}` : mask.content
      } else {
        this.selectedMasks.splice(maskIndex, 1)

        const contentToRemove = mask.content

        if (this.reportText === contentToRemove) {
          this.reportText = ''
        } else if (this.reportText.endsWith(`\n${contentToRemove}`)) {
          this.reportText = this.reportText.replace(`\n${contentToRemove}`, '')
        } else if (this.reportText.includes(`${contentToRemove}\n`)) {
          this.reportText = this.reportText.replace(`${contentToRemove}\n`, '')
        } else {
          this.reportText = this.reportText.replace(contentToRemove, '')
        }
      }
    },

    checkAndDeselectMasks() {
      if (!this.selectedMasks.length) return

      const selectedMasksCopy = [...this.selectedMasks]

      for (const mask of selectedMasksCopy) {
        const contentExists = this.isMaskContentInReport(mask.content)

        if (!contentExists) {
          const maskIndex = this.selectedMasks.findIndex((m) => m.id === mask.id)
          if (maskIndex !== -1) {
            this.selectedMasks.splice(maskIndex, 1)
          }
        }
      }
    },

    isMaskContentInReport(content) {
      if (!content || !this.reportText) return false

      if (this.reportText.includes(content)) return true

      const normalizedReportText = this.reportText.replace(/\s+/g, ' ').trim()
      const normalizedContent = content.replace(/\s+/g, ' ').trim()

      return normalizedReportText.includes(normalizedContent)
    },
    async handleForm() {
      try {
        if (this.repeat) {
          await this.examRepeatRadio(this.currentExam.examId, this.reportText)
        } else {
          await this.sendReportRadio(this.currentExam.examId, {
            reportText: this.reportText,
            abnormal: this.reportStatus === 'alterado'
          })
        }
        this.clearReportFromStorage(this.currentExam.examId)
        this.resetForm()

        await this.load()

        this.$nextTick(() => {
          if (this.sortedItems.length > 0) {
            this.selectExam(this.sortedItems[0].examId)
          }
        })

        this.showFeedback()
      } catch (error) {
        this.$root.$emit('toast', {
          message: this.$i18n.t('radio_report.error_sending_report'),
          type: 'error'
        })
      }
    },
    toggleReportStatus(status) {
      if (this.reportStatus === status) {
        this.reportStatus = ''
        this.showMasks = false
        this.showSpecialtyDropdown = false
        this.selectedSpecialty = null
        this.selectedMasks = []
      } else {
        this.handleStatusChange(status)
      }
      if (this.repeat) {
        this.repeat = false
      }
    },
    autoSelectSpecialtyIfOnlyOne() {
      if (this.filteredSpecialties && this.filteredSpecialties.length === 1) {
        this.selectSpecialty(this.filteredSpecialties[0])
        this.showMasks = true
      }
    },
    async load() {
      this.isLoading = true
      this.hasLoaded = false
      try {
        let items = await this.getRadioExamsList()
        items = items.sort((a, b) => {
          if (a.emergency !== b.emergency) return b.emergency - a.emergency
          if (a.urgent !== b.urgent) return b.urgent - a.urgent
          if (!!a.comment !== !!b.comment) return b.comment - a.comment
          return a.patientName.localeCompare(b.patientName)
        })

        this.items = items
        this.lastUpdated = new Date()

        if (this.query) {
          this.filterItems()
        } else {
          this.filteredItems = [...this.items]
        }

        this.hasLoaded = true

        this.$nextTick(() => {
          this.reorderItems()

          if (this.filteredItems.length > 0) {
            const firstExamId = this.filteredItems[0].examId
            this.selectExam(firstExamId)
            this.reRenderRadioHeader()
            this.stopTimer()
          } else {
            this.isTimerActive = true
            this.startTimer()
          }
        })
      } catch (error) {
        console.error('Erro ao carregar exames:', error)
      } finally {
        this.isLoading = false
      }
    },
    startTimer() {
      if (this.interval) return
      this.interval = setInterval(async () => {
        await this.load()
      }, 30000)
    },
    stopTimer() {
      clearInterval(this.interval)
      this.interval = null
    },
    resetRadioMaskButtons() {
      this.abnormal = null
      if (this.$refs.radioMask) {
        this.$refs.radioMask.resetButtons()
      }
    },
    handleRefreshConfirmation() {
      if (this.reportText.length > 0) {
        this.showDialog = 2
        return
      }
      return this.handleRefresh()
    },
    async handleRefresh() {
      try {
        this.items = await this.getRadioExamsList()
        this.lastUpdated = new Date()
        this.filterItems()
        this.reorderItems()

        if (this.sortedItems.length > 0) {
          this.selectExam(this.sortedItems[0].examId)
        }

        this.$root.$emit('toast', {
          message: this.$i18n.t('radio_report.exams_list_updated'),
          type: 'success'
        })
      } catch (error) {}
    },
    async refreshAfterSendInformation() {
      await this.load()

      if (this.sortedItems.length > 0) {
        this.selectExam(this.sortedItems[0].examId)
      }
    },
    filterItems() {
      this.filteredItems = this.items
        .filter(
          (item) =>
            item.patientName.toLowerCase().includes(this.query.toLowerCase()) ||
            item.examId.toString().includes(this.query) ||
            item.specialtyName.toLowerCase().includes(this.query.toLowerCase())
        )
        .map((item) => {
          item.truncatedPatientName = this.truncatePatientName(item.patientName)
          return item
        })

      this.reorderItems()

      if (!this.currentExam) {
        this.isTimerActive = true
        this.startTimer()
      }
    },
    handleRowSelected(examId) {
      if (this.examSelected === examId) {
        return
      }
      this.reRenderKey++
      if (this.reportText.trim().length > 0) {
        this.showDialog = 1
        this.nextExamId = examId
      } else {
        this.selectExam(examId)
      }
      this.isEditClicked = false
    },
    handleCloseComment() {
      this.viewingComment = false
    },
    handleCloseObservation() {
      this.viewingObservation = false
      this.$nextTick(() => {
        this.reorderItems()
      })
    },
    reorderItems() {
      this.filteredItems = [...this.filteredItems].sort((a, b) => {
        if (a.emergency !== b.emergency) return b.emergency - a.emergency
        if (a.urgent !== b.urgent) return b.urgent - a.urgent
        return new Date(a.openingDate) - new Date(b.openingDate)
      })
    },
    reRenderRadioHeader() {
      this.reRenderKey++
    },
    selectExam(examId) {
      this.pinnedExam = examId
      this.examSelected = examId
      this.showEditButton = this.examDetails && this.examDetails.retification
      this.resetRadioMaskButtons()
      this.resetForm()
    },
    resetForm() {
      this.reportStatus = ''
      this.repeat = false
      this.reportText = ''
      this.isAbnormal = false
      this.selectedMasks = []
      this.selectedSpecialty = null
      this.showSpecialtyDropdown = false
      this.resetRadioMaskButtons()
    },
    handleRepeatChange() {
      if (this.repeat) {
        this.reportStatus = ''
        this.showSpecialtyDropdown = false
        this.autoSelectSpecialtyIfOnlyOne()
      }
    },
    clearReportData() {
      this.description = ''
      if (this.$refs.report) {
        this.$refs.report.clear()
      }
    },
    clearReportFromStorage(examId) {
      const savedReports = JSON.parse(localStorage.getItem('savedReports')) || []
      const updatedReports = savedReports.filter((report) => report.id !== examId)
      localStorage.setItem('savedReports', JSON.stringify(updatedReports))
    },
    confirmChangeExam() {
      if (this.showDialog === 1) {
        this.clearForm()
        this.clearReportFromStorage(this.examSelected)
        this.selectExam(this.nextExamId)
        this.nextExamId = null
      } else {
        this.handleRefresh()
      }
      this.showDialog = 0
    },
    cancelChangeExam() {
      this.showDialog = 0
      this.nextExamId = null
    },
    async handleViewObservation(item) {
      this.examSelected = item.examId
      this.currentExam.notes = await this.getExamNotes(item.examId)
      this.viewingObservation = true
    },
    onChangeStatus(abnormal) {
      this.abnormal = abnormal
    },
    changeDescription(newDescription) {
      this.description = newDescription
    },
    showFeedback() {
      this.$root.$emit('toast', {
        message: this.$i18n.t('radio_report.successfully_sent'),
        type: 'success'
      })

      this.$nextTick(() => {
        if (this.sortedItems.length > 0) {
          this.selectExam(this.sortedItems[0].examId)
        }
      })

      this.examSelected = null
      this.description = ''
      this.abnormal = null
      this.isReadOnly = false

      if (this.$refs.report) {
        this.$refs.report.clear()
      }
    },
    truncatePatientName(name, maxLength = 23) {
      if (name.length <= maxLength) return name

      const nameParts = name.split(' ')
      let truncatedName = nameParts[0]

      for (let i = 1; i < nameParts.length; i++) {
        if ((truncatedName + ' ' + nameParts[i]).length > maxLength) {
          truncatedName += '...'
          break
        }
        truncatedName += ' ' + nameParts[i]
      }
      return truncatedName
    }
  },
  beforeDestroy() {
    this.stopTimer()
  }
}

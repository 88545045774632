import MedicoHeader from '@/components/medico/medicoHeader/index.vue'
import Mascara from '@/components/medico/mascara/index.vue'
import Report from '@/components/medico/report/index.vue'
import Laudar from '@/components/medico/laudar-exame/index.vue'
import ExamObservation from '@/components/medico/exam-observation/index.vue'
import ExamRetification from '@/components/medico/exam-retification/index.vue'
import Oit from '@/components/medico/oit/index.vue'
import CONSTANTS from '@/shared/constants/constants'

export default {
  name: 'medico',
  components: {
    MedicoHeader,
    Mascara,
    Report,
    Laudar,
    ExamObservation,
    ExamRetification,
    Oit
  },
  props: {
    exam: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      description: '',
      status: 0,
      examId: 0,
      observationOpened: true,
      retificationOpened: true,
      isReadOnly: false,
      STATUS: CONSTANTS.STATUS,
      isOit: false
    }
  },
  mounted () {
    this.isOit = this.exam.specialityId === CONSTANTS.SPECIALITY.RAIOXOIT
    this.observationShow = false
    this.observationShow = this.exam.observations.length
    this.isReadOnly = this.exam.status === this.STATUS.AGUARDANDO_RETIFICACAO || this.exam.status === this.STATUS.RETIFICANDO
    if (this.isReadOnly) {
      const reportStatus = this.exam.isAlterado ? CONSTANTS.REPORT_STATUS.ALTERADO : CONSTANTS.REPORT_STATUS.NORMAL
      this.$refs.laudar.setLaudo(this.exam.description, reportStatus, this.exam.masks)
    }
  },
  methods: {
    shouldShowObservations () {
      if (this.isOit) {
        if(this.exam.observations && this.exam.observations.length && this.exam.observations.some(obs => obs.content && obs.content.length >= 5)){
          return true
        }
        return false
      }
      if (this.exam.observations && this.exam.observations.length) {
        return true
      }
      return false
    },
    changeStatus (status) {
      this.status = status
      this.validateLaudar()
    },
    changeDescription (description) {
      this.description = description
      this.validateLaudar()
      this.validateRepeat()
    },
    validateLaudar () {
      let masks = []
      if (this.$refs.mascara) {
        masks = this.$refs.mascara.getListIdUsedMask()
      }
      this.$refs.laudar.setLaudo(this.description, this.status, masks)
    },
    validateRepeat () {
      let reasons = []
      if (this.$refs.mascara) {
        reasons = this.$refs.mascara.getListIdUsedReason()
      }
      this.$refs.laudar.setRepeat(this.description, reasons)
    },
    showFeedback (hasMoreExams) {
      this.$refs.medicoHeader.stopCountDown()
      this.$emit('showFeedback', hasMoreExams)
    },
    openCard (value) {
      if (value === 'observations') {
        this.observationOpened = true
      } else {
        this.retificationOpened = true
      }
    },
    closeCard (value) {
      if (value === 'observations') {
        this.observationOpened = false
      } else {
        this.retificationOpened = false
      }
    },
    addMask (maskText) {
      this.$refs.report.addText(maskText)
    },
    removeMask () {
      this.validateLaudar()
    },
    getClassSizeObservation () {
      return {
        'md-size-10': !this.observationOpened,
        'md-size-25': this.observationOpened
      }
    },
    getClassCard (value) {
      const isClosed = value === 'observations' ? !this.observationOpened : !this.retificationOpened
      const isOpened = value === 'observations' ? this.observationOpened : this.retificationOpened

      return {
        'md-size-10': isClosed,
        'md-size-25': isOpened
      }
    },
    sendInformation (data) {
      this.$emit('showFeedback', data)
    },
    clickRectify () {
      this.isReadOnly = false
      this.$refs.report.clear()
      this.$refs.mascara.clear()
    },
    enableOitReportButton (oitReport) {
      this.description = JSON.stringify(oitReport)
      this.status = oitReport.Radiography.Normal === true ? CONSTANTS.REPORT_STATUS.NORMAL : CONSTANTS.REPORT_STATUS.ALTERADO
      this.validateLaudar()
    },
    reloadOitReport () {
      const oitReport = this.$refs.oit.getReport()
      this.enableOitReportButton(oitReport)
      this.$refs.laudar.laudar()
    }
  }
}

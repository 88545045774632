import UrgentMedicalReport from '@/components/exames/urgent-medical-report/index.vue'
import StatusMixin from '@/shared/mixins/status.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import EmergencyMedicalReportMixin from '@/shared/mixins/emergency-medical-report.mixin'
import ExamMixin from '@/shared/mixins/exam.mixin'
import ExamObservation from '@/shared/mixins/exam-observation.mixin'

import { ref } from 'vue'
export default {
  name: 'exam-details-subheader',
  components: {
    UrgentMedicalReport
  },
  props: {
    details: {
      type: Object,
      required: true
    }
  },
  mixins: [
    StatusMixin,
    AuthMixin,
    EmergencyMedicalReportMixin,
    ExamMixin,
    ExamObservation,
  ],
  setup () {
    const isChecked = ref(false)
    return {
      isChecked
    }
  },
  data () {
    return {
      urgent: false,
      showUrgent: false,
      newObservation: null,
      creationDate: null,
      isChecked: false,
      isConfirmed: false,
      isConfirmedOpened: true,
      emergency: false,
      showEmergencySwitch: false,
      modelUrgent: false,
      modelEmergency: false
    }
  },
  watch: {
    modelEmergency(newVal) {
      this.$root.$emit('updateModelEmergency', newVal)
    }
  },
  async mounted () {
    if (this.details.emergency) {
      this.modelEmergency = true
    }
    if (this.details.urgent) {
      this.modelUrgent = true
    }
    if (this.details.emergency) {
      this.isChecked = true
      this.isConfirmed = true
      this.isConfirmedOpened = false
      this.emergency = true
    }
    const sourceDetails = await this.getSourceDetails()
    this.showEmergencySwitch = sourceDetails.showEmergencySwitch
  },

  computed: {
    hasAddObservation () {
      if (!this.details) {
        return false
      }
    },
    observation () {
      if (!this.details.observations.length) {
        return {}
      }
      const observations = this.details.observations.map(observation => observation)
      this.creationDate = new Date().toISOString()
      return observations
    },
    isEmergency () {
      return this.isEmergencySource()
    },
    showEmergency () {
      return this.details.emergency === true
    },
    
  },
  methods: {
    cancelUrgent () {
      this.urgent = false
      this.showUrgent = false
      this.modelUrgent = false
    },
    
    confirmUrgent (examId) {
      this.cancelUrgent()
      this.$emit('confirmUrgent', examId)
      this.modelUrgent = true
    },

    async addObservation () {
      const observation = {
        content: this.newObservation,
        examId: this.details.examId,
        creationDate: new Date().toISOString(),
        nameUser: this.getFullName()
      }

      this.$root.$emit('showLoading')
      try {
        const result = await this.addExamObservation(observation)
        this.details.status.id = result.data.data.status.id
        this.details.status.name = result.data.data.status.name
        const statusId = result.data.data.status.id
        this.details.observations = [observation]
        this.$emit('addObservation', statusId)
      } finally {
        this.$root.$emit('hideLoading')
      }
    },
    async confirmEmergency() {
      try {
        if (this.details.observations.length && Object.keys(this.details.observations).length) {
          this.details.emergency = true
          this.details.emergencyAt = new Date().toISOString()
          this.$root.$emit('showLoading')
          await this.setEmergency(this.details.examId,
            this.details.emergency)
          this.isChecked = true
          this.isConfirmed = true
          this.emergency = true
          this.$emit('submitDetails', this.details)
          this.$root.$emit('refreshFromDetails', this.details)
          this.$root.$emit('hideLoading')
          return
        }

        if ((this.details.observations.length === 0 || !this.details.observations) && (!this.newObservation || this.newObservation.length < 12)) {
          this.$root.$emit('toast', {
            message: 'A observação deve ter no mínimo 12 caracteres.',
            type: 'info'
          })
          return
        }
        this.details.emergency = true
        this.details.emergencyAt = new Date().toISOString()
        this.details.observations = this.newObservation
        this.$root.$emit('showLoading')
        await this.addObservation()
        await this.setEmergency(this.details.examId,
          this.details.emergency)
        this.isChecked = true
        this.isConfirmed = true
        this.emergency = true
        this.$emit('submitDetails', this.details)
        this.$root.$emit('hideLoading')
      } catch (error) {

        this.emergency = this.details.emergency
        this.details.emergencyAt = null
        this.$root.$emit('hideLoading')
      }

      this.$root.$emit('refreshFromDetails', this.details)
      this.isChecked = false
      this.isConfirmed = false
      this.newObservation = ''
      this.isConfirmedOpened = true
    },


    cancelEmergency () {
      this.isChecked = false
      this.isConfirmed = false
      this.isConfirmedOpened = true
      this.modelEmergency = false
      this.modelUrgent = false
      this.newObservation = ''
    },

    sendObservation () {
      this.$emit('sendObservation', this.observation)
    }
  }
}

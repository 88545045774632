import BaseMixin from './base.mixin'
const mixin = {
  mixins: [
    BaseMixin
  ],
  methods: {
    setEmergency (examId, emergency) {
      return this.requestPost('/core/Exam/SetEmergency', { examId, emergency })
    }
  }
}

export default mixin

import BaseMixin from './base.mixin'
import LocalStorageMixin from '@/shared/mixins/local-storage.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'

const mixin = {
  mixins: [BaseMixin, LocalStorageMixin, AuthMixin],
  methods: {
    async getRadioExamsList() {
      try {
        const response = await this.requestGet(`/core/FastReport`)
        if (response && response.data) {
          return response.data
        } else {
          throw new Error('Resposta da API inválida')
        }
      } catch (error) {
        throw new Error('Erro ao buscar lista de exames: ' + error.message)
      }
    },
    async getDetails(examId) {
      try {
        const response = await this.requestGet(`/core/Exams/${examId}`)
        if (response && response.data) {
          return response.data
        } else {
          throw new Error('Resposta da API inválida')
        }
      } catch (error) {
        console.error('Erro ao buscar detalhes do exame:', error)
        return ''
      }
    },
    async examRepeatRadio(examId, reason) {
      try {
        const response = await this.requestPost(`/core/FastReport/${examId}/Repeat`, {
          reason
        })

        if (response && response.data) {
          return response.data
        } else {
          throw new Error('Resposta da API inválida')
        }
      } catch (error) {
        throw new Error('Erro ao repetir o exame: ' + error.message)
      }
    },

    async getExamNotes(examId) {
      try {
        const response = await this.requestGet(`/core/FastReport/${examId}/Notes`)
        if (response && response.data) {
          return response.data.notes
        } else {
          throw new Error('Resposta da API inválida')
        }
      } catch (error) {
        throw new Error('Erro ao buscar relatório do exame: ' + error.message)
      }
    },

    async getMaskOptions(specialityId) {
      try {
        const endpoint = `/core/Physician/Mask?specialityId=${specialityId}`
        const response = await this.requestGet(endpoint)
        if (!response || !response.data) {
          console.warn('No data in mask response')
          return { data: [] }
        }

        return response
      } catch (error) {
        console.error('Error in getMaskOptions:', error)
        if (error.response) {
          console.error('Error response:', error.response)
        }
        return { data: [] }
      }
    },

    async sendReportRadio(examId, payload) {
      try {
        const response = await this.requestPost(`/core/FastReport/${examId}/Report`, payload, {
          headers: { 'Content-Type': 'application/json' }
        })
        return response
      } catch (error) {
        throw new Error(
          'Erro ao enviar o relatório: ' + (error.response?.data?.errors[0]?.message || 'Erro desconhecido')
        )
      }
    }
  }
}

export default mixin

import ExamUrgencyAlertMixin from '@/shared/mixins/exam-urgency-alert.mixin'
import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import CONSTANTS from '@/shared/constants/constants'
import RadioExamsMixin from '@/shared/mixins/radio-report.mixin'

export default {
  name: 'exam-details-alert',
  props: {
    exam: {
      type: Object,
      required: true
    }
  },
  mixins: [
    DateMixin,
    ExamUrgencyAlertMixin,
    RoleMixin,
    AuthMixin,
    RadioExamsMixin
  ],
  data () {
    return {
      placeholder: this.$i18n.t('inform_alert_reason'),
      informations: [],
      roles: [],
      STATUS: CONSTANTS.STATUS,
      toggle: false,
      maxLength: 1000,
      message: null,
      urgencyTooltip: this.$i18n.t('tooltips.already_has_urgency_alert'),
      hasAlert: false
    }
  },

  computed: {
    tooltipMessage () {
      if (this.exam.hasUrgencyAlert) {
        return this.$i18n.t('tooltips.already_has_urgency_alert')
      }
      if (this.hasAlert) {
        return this.$i18n.t('tooltips.already_has_urgency_alert')
      }
      return undefined
    }
  },
  methods: {
    async clickSendUrgencyAlert () {
      this.$root.$emit('showLoading')
      try {
        await this.sendUrgencyAlert({
          examId: this.exam.examId,
          description: this.message
        })
        this.exam.hasUrgencyAlert = true
        this.message = null
        this.$emit('rerender')
        this.hide()
      } finally {
        this.$root.$emit('hideLoading')
        this.$root.$emit('toast', { message: this.$i18n.t('feedback.alert_sent'), type: 'info' })
      }
    },
    focus () {
      this.placeholder = ''
    },
    blur () {
      this.placeholder = this.$i18n.t('inform_alert_reason')
    },
    hide () {
      this.toggle = false
    }
  }
}

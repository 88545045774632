import routes from '@/router/routes'
import AuthMixin from '@/shared/mixins/auth.mixin'

export default {
  name: 'login',
  components: {},
  mixins: [
    AuthMixin
  ],
  props: {
    isPiauiDomain: {
      type: Boolean,
      default: false
    },
    isAmazonasDomain: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      logo: this.getLogoByURL(),
      authRoute: import.meta.env.VITE_AUTH_ROUTE,
      urlApi: import.meta.env.VITE_URL_API,
      routes,
      recaptchaPublicKey: import.meta.env.VITE_RECAPTCHA_PUBLIC_KEY
    }
  },
  mounted () {
    localStorage.removeItem("selectedSource")

    const logoElement = this.$el.querySelector('.logo')
    if (!logoElement) return

    const domain = window.location.hostname
    const isPiauiDomain = domain.match(/^(dev|hml|)\.?laudos\.piauisaudedigital\.com\.br/)
    const isAmazonasDomain = domain.match(/^(dev|hml|)\.?laudos\.saudeamdigital\.am\.gov\.br/)

    const isPiauiLogo = this.logo.includes('logo-piaui-saude-digital.png')
    const isAmazonasLogo = this.logo.includes('logo-amazonas-saude-digital.png')

    if (isPiauiDomain && isPiauiLogo) {
      logoElement.classList.add('piaui-logo')
    } else if (isAmazonasDomain && isAmazonasLogo) {
      logoElement.classList.add('amazonas-logo')
    }

    const role = this.getFirstRole()
    if (role) {
      this.$router.push(`/${this.getHomePage()}`)
    }
    this.$root.$emit('hideLoading')
  },
  methods: {
    getLogoByURL () {
      const url = window.location.hostname
      const piauiRegex = /^(dev|hml|)\.?laudos\.piauisaudedigital\.com\.br/
      const amazonasRegex = /^(dev|hml|)\.?laudos\.saudeamdigital\.am\.gov\.br/

      if (piauiRegex.test(url)) {
        return '../../assets/img/logo-piaui-saude-digital.png'
      } else if (amazonasRegex.test(url)) {
        return '../../assets/img/logo-amazonas-saude-digital.png'
      } else {
        return '../../assets/img/logo-portal-telemedicina.png'
      }
    }
  }
}

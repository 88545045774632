import DateMixin from '@/shared/mixins/date.mixin'
import RoleMixin from '@/shared/mixins/role.mixin'
import SpecialtyMixin from '@/shared/mixins/specialty.mixin'
import StatusMixin from '@/shared/mixins/status.mixin'
import ExamMixin from '@/shared/mixins/exam.mixin'
import AuthMixin from '@/shared/mixins/auth.mixin'
import ExamUrgencyAlertMixin from '@/shared/mixins/exam-urgency-alert.mixin'
import Filter from '@/shared/filter/filter'
import CONSTANTS from '../../shared/constants/constants'
import ExamNotificationUrgencyAlert from '@/components/exame-notification-urgency-alert/exame-notification-urgency-alert.component'
import PopupNotification from '@/components/exames-cpf/popup-notification/popup-notification.component'
import PendencyAlert from '@/components/exames/pendency-alert/pendency-alert.component'
import UrgencyIcon from '@/assets/svg/alert-test.svg?raw'
import BackIcon from '@/assets/svg/back_to_exams.svg?raw'
import ReportIcon from '@/assets/svg/report.svg?raw'
import LaudoIcon from '@/assets/svg/status/laudo_liberado.svg?raw'
import PendencyIcon from '@/assets/svg/status/pendencias.svg?raw'
import RepeatIcon from '@/assets/svg/status/repetir.svg?raw'
import WaitingIcon from '@/assets/svg/status/aguardando_laudo.svg?raw'
import PrintIcon from '@/assets/img/print-icon.png?raw'
import AlertUrgency from '@/assets/svg/status/alerta_urgencia.svg?raw'

import { mapActions, mapGetters } from 'vuex'


const EXAM_TAB = 0

export default {
  name: 'busca-por-cpf',
  mixins: [
    DateMixin,
    RoleMixin,
    SpecialtyMixin,
    StatusMixin,
    ExamMixin,
    AuthMixin,
    ExamUrgencyAlertMixin
  ],
  components: {
    ExamNotificationUrgencyAlert,
    PopupNotification,
    PendencyAlert
  },
  data () {
    return {
      goBackIcon: BackIcon,
      activeFilters: {},
      items: [],
      headers: [
        '',
        '',
        this.$i18n.t('header.name'),
        this.$i18n.t('header.status'),
        this.$i18n.t('header.exam_type'),
        this.$i18n.t('header.number'),
        this.$i18n.t('header.date'),
        this.$i18n.t('header.source')
      ],
      specialties: [],
      status: [],
      total: 0,
      page: 1,
      pageSize: 10,
      selectedFilters: {},
      placeholderSearch: this.$i18n.t('placeholder_search_by_document'),
      maxCharChip: 50,
      selectedRows: [],
      selectAll: false,
      STATUS: CONSTANTS.STATUS,
      roles: [],
      defaultTabs: [
        {
          id: EXAM_TAB,
          title: this.$i18n.tc('report', 2),
          icon: ReportIcon
        }
      ],
      details: null,
      examInfos: [],
      defaultExamInfos: [
        { id: 10, name: this.$i18n.t('released_report'), totalExams: '...', icon: LaudoIcon },
        { id: 300, name: this.$i18n.tc('pendencies', 1), totalExams: '...', icon: PendencyIcon },
        { id: 95, name: this.$i18n.t('repeat'), totalExams: '...', icon: RepeatIcon },
        { id: 22, name: this.$i18n.t('waiting_report'), totalExams: '...', icon: WaitingIcon }
      ],
      activeTab: 0,
      startSlideDate: '',
      endSlideDate: '',
      instructionText: this.$i18n.t('portal_telemedicina_requires_related_information'),
      printIcon: PrintIcon,
      printedOptions: [
        {
          id: true,
          name: this.$i18n.t('yes')
        },
        {
          id: false,
          name: this.$i18n.t('no')
        }
      ],
      payloadPopupNotification: {},
      openPopupNotification: false,
      infoUrgencyAlert: {
        id: 104,
        name: this.$i18n.t('urgency_alert'),
        totalExams: '...',
        icon: AlertUrgency
      },
      listUrgencyAlert: [],
      qtdPendency: 0,
      showObservationTab: true,
      seenPendencyAlert: false,
      urgencyIcon: UrgencyIcon,
      urgencyTooltip: this.$i18n.t('tooltips.urgency_alert')
    }
  },
  computed: {
    ...mapGetters({
      getUpdateGridExams: 'getUpdateGridExams',
      getSidebarStatus: 'getSidebarStatus'
    }),
    exams () {
      return this.items.map((item) => {
        return {
          printed: item.printed,
          selectable: item.status.id === this.STATUS.LAUDO_LIBERADO,
          patientName: item.patientName,
          status: item.status.id === 18 || item.status.id === 26 ? {id: 300, name: this.$i18n.t('pending')} : this.getStatusById(item.status.id),
          speciality: item.speciality.name,
          id: item.examId,
          creationDate: this.getFormattedDateHour(item.creationDate),
          sourceName: item.sourceName,
          dtUltAtu: this.getFormattedDateHour(item.dtUltAtu),
          class: this.getClasses(item),
          hasUrgency: item.urgencyAlert
        }
      })
    },
    statusIcon () {
      const status = this.defaultExamInfos.find((status) => {
        return status.id === this.details.status.id
      })

      return status ? status.icon : this.defaultExamInfos[2].icon
    },
    hasPendency () {
      return this.qtdPendency !== 0
    }
  },
  watch: {
    getUpdateGridExams (newValue) {
      if (newValue) {
        this.updateGridExams()
      }
    },
    getSidebarStatus (newValue) {
      if (newValue) {
        this.closeSidebar()
      }
    }
  },
  async mounted () {
    this.examInfos = this.defaultExamInfos

    this.status = await this.getStatus()
    this.specialties = await this.getSpecialties()

    this.loadUrgencyAlerts()
    this.$root.$on('WebSocketUrgencyAlert', () => this.loadUrgencyAlerts())

    this.unsetPrintedReports()
  },
  methods: {
    ...mapActions(['changeUpdateGridExams', 'changeSidebarStatus']),
    ...mapGetters([]),

    removeDiacritics (value) {
      return value.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },

    async getExames () {
      if (!this.activeFilters.Cpf) {
        this.items = []
        return false
      }
      this.$root.$emit('showLoading')
      this.$refs.grid.hideDetails()

      try {
        this.items = []
        this.setFilters()
        const items = await this.getExamsByDocument(this.activeFilters)
        const page = items.page
        const pageSize = items.pageSize
        const totalExams = items.totalExams

        if (totalExams / pageSize < page && page !== 0) {
          this.page--
          return this.getExames()
        }

        this.items = items.data
        this.total = items.totalExams
        return true
      } catch (errors) {
        return false
      } finally {
        this.$root.$emit('hideLoading')
      }
    },

    setFilters () {
      this.activeFilters.Page = { value: this.page - 1 }
      this.activeFilters.PageSize = { value: this.pageSize }
    },

    clearSelectedRows () {
      this.selectedRows = []
      this.selectAll = false
    },

    reloadExames () {
      this.clearSelectedRows()
      this.page = 1
      this.pageSize = 10
      return this.getExames()
    },

    changePagination (data) {
      this.clearSelectedRows()
      if (this.pageSize !== parseInt(data.pageSize)) {
        this.page = 1
      } else {
        this.page = parseInt(data.currentPage)
      }
      this.pageSize = parseInt(data.pageSize)
      this.getExames()
    },

    async addFilter (filter, reload = true) {
      let filters = []
      if (Array.isArray(filter)) {
        filters = filter
      } else {
        filters.push(filter)
      }

      filters.forEach((filter) => {
        this.activeFilters[filter.key] = {
          value: filter.value,
          keyLabel: filter.keyLabel,
          valueLabel: filter.valueLabel
        }
      })

      if (!reload || await this.reloadExames()) {
        filters.forEach((filter) => {
          this.$refs.chipsComponent.addChip(filter.keyLabel, filter.valueLabel, filter.key)
        })
        this.$refs.search.clear()
      } else {
        filters.forEach((filter) => {
          delete this.activeFilters[filter.key]
        })
      }
    },

    deleteFilter (key) {
      if (key) {
        delete this.activeFilters[key]
      }

      this.reloadExames()
    },

    showLaudo (examId) {
      const route = this.$router.resolve({ name: 'examesPdf', query: { id: examId } })
      window.open(route.href, '_blank')
    },

    search (value) {
      const digits = value.replace(/\D/g, '')
      this.addFilter(new Filter('Cpf', digits, this.$i18n.t('document')), false)
      this.reloadExames()
    },

    searchError (errorMessage) {
      if (errorMessage) {
        this.$root.$emit('toast', { message: errorMessage, type: 'info' })
      }
    },

    clearFilters () {
      this.activeFilters = {}
      this.items = []
    },

    getClasses (exam) {
      return {
        'urgent': exam.urgent,
        'viewed': exam.viewed,
        'needAttention': (exam.status.id === this.STATUS.AGUARDANDO_INFORMACAO || exam.status.id === this.STATUS.AGUARDANDO_OBSERVACAO) && !exam.viewed,
        'not-viewed': (exam.status.id === this.STATUS.LAUDO_LIBERADO ||
          exam.status.id === this.STATUS.REPETIR) && !exam.viewed
      }
    },

    selectAllRows () {
      if (this.selectAll) {
        this.selectedRows = this.exams.filter((item) => {
          return item.selectable
        })
      } else {
        this.selectedRows = []
      }
    },

    printLaudos () {
      if (this.hasPrintedReports()) {
        this.$root.$emit('toast', { message: this.$i18n.t('wait_there_are_files_being_printed'), type: 'info' })
      } else if (this.selectedRows.length) {
        this.setPrintedReports()
        this.$root.$emit('toast', { message: this.$i18n.t('preparing_files'), type: 'info' })

        const arrayIds = this.selectedRows.map((item) => {
          return item.id
        })

        arrayIds.forEach((examId) => {
          const item = this.items.find((item) => {
            return item.examId === examId
          })

          item.printed = true
        })

        const route = this.$router.resolve({ name: 'examesPdf', query: { id: JSON.stringify(arrayIds) } })
        window.open(route.href, '_blank')
      }
    },

    resetSelectAll () {
      this.selectAll = false
    },

    getHeader (index) {
      return this.headers[index]
    },

    changeTab (index) {
      this.activeTab = index
      this.getExames()
    },

    toggleResumePainel () {
      this.showResumePanel = !this.showResumePanel
      this.reloadExames()
    },

    updateStatus (statusId) {
      const exam = this.items.find((exam) => {
        return exam.examId === this.details.examId
      })
      if (exam.status.id !== statusId) {
        this.examInfos.forEach(item => {
          if (item.id === exam.status.id) {
            item.totalExams--
          } else if (item.id === statusId) {
            item.totalExams++
          }
        })
        exam.status.id = statusId
      }
    },

    updateUrgent (examId) {
      const item = this.items.find((item) => {
        return item.examId === examId
      })
      this.details.urgent = true
      item.urgent = true
    },

    cancelUrgent () {
      if (this.$refs.subheader) {
        this.$refs.subheader.cancelUrgent()
      }
    },

    sendInformation () {
      this.updateStatus(this.pendingReport)
    },

    hasAddObservation (index) {
      const item = this.items[index]
      const originalStatus = item.originalStatus.id
      if (item.observation) {
        return false
      }

      return !(originalStatus === this.STATUS.LAUDO_LIBERADO || originalStatus === this.STATUS.LAUDANDO)
    },
    setFilterPrinted (printed) {
      this.addFilter(new Filter('Printed', printed.id, this.$i18n.t('printed'), printed.name))
    },
    refreshFilters () {
      this.reloadExames()
    },
    showPopupNotification (payload) {
      this.payloadPopupNotification = payload
      this.openPopupNotification = true
    },
    closeSuccessPopupNotification () {
      const urgencyID = this.payloadPopupNotification.urgencyID
      this.listUrgencyAlert = this.listUrgencyAlert.filter(item => item.urgencyID !== urgencyID)
      this.payloadPopupNotification = {}
      this.openPopupNotification = false
    },
    closeCancelPopupNotification () {
      this.payloadPopupNotification = {}
      this.openPopupNotification = false
    },
    async loadUrgencyAlerts () {
      const array = await this.getAllNoCheckedAlerts()
      this.listUrgencyAlert = array.reverse()
    },
    hideAlert () {
      this.seenPendencyAlert = true
      const status = {id: 300, name: this.$i18n.tc('pendencies', 1)}
      this.setFilterStatus(status)
    },
    updateGridExams () {
      this.reloadExames()
      this.changeUpdateGridExams(false)
    },
    closeSidebar () {
      this.$refs.grid.hideDetails()
      this.changeSidebarStatus(false)
    },
    backToExams () {
      this.$router.push({ path: '/exames' })
    }
  }
}

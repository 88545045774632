import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthService from '@/shared/services/auth.service'
import routes from './routes'
import { requestGet } from '@/shared/services/interceptor.service'

Vue.use(VueRouter)

const authService = AuthService()
const router = new VueRouter({ mode: 'history', routes })

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== 'NavigationDuplicated' && !/Redirected when going/.test(err.message)) {
      throw err
    }
  })
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}

router.beforeEach(async (to, from, next) => {
  const roleName = authService.getFirstRole()
  const listExam = authService.getListExam()


  // Lógica de redirecionamento para 'processamento-arquivos'
  if (to.name === 'processamento-arquivos' && roleName === 'source') {
    try {
      const allowPreOrder = await requestGet('/core/ExamSource/DisplayOptions', { useSelectedSource: true })
      if (!allowPreOrder.data.data.preOrder) {
        return next('/exames') // Redireciona para 'exames' se preOrder não for permitido
      }
    } catch (error) {
      console.error('Erro ao verificar preOrder:', error)
      return next('/exames') // Em caso de erro, redireciona para 'exames'
    }
  }

  // Lógica de validação para médicos
  if (roleName === 'physician') {
    if (to.path !== '/' && to.path !== '/medico/validate' && !sessionStorage.getItem('isValidatedPhysician')) {
      return next('/medico/validate')
    }
    if (to.path === '/medico') {
        if (listExam && authService.getOnlyReports()) {
            return next('/medico/laudar-radio'); // Redireciona para 'listexam' se ambos forem true
        }

        if (authService.getOnlyReports()) {
            return next('/medico/laudar'); // Redireciona para 'onlyreports' se apenas `onlyReports` for true
        }

        if (listExam) {
            return next('/medico/laudar-radio'); // Redireciona para 'listexam' se apenas `listExam` for true
        }
    }

    if (!authService.getOnlyReports() && to.path === '/medico/laudar') {
      return next('/medico/laudar-radio')
    }
    if (!listExam && to.path === '/medico/laudar-radio') {
      return next('/medico/laudar')
    }

    if (!authService.getOnlyReports() && !listExam ) {
      return next()
    }

    if (
      authService.getOnlyReports() &&
      !listExam &&
      !['/medico/laudar', '/', '/medico/validate', '/medico/laudar-radio'].includes(to.path)
    ) {
      return next('/medico/laudar')
    }

    if (listExam && to.meta.listExam && to.path !== '/medico/laudar-radio') {
      return next('/medico/laudar-radio')
    }

    
  }

  // Verifica se o usuário tem acesso à rota
  if (to.meta.roles && !to.meta.roles.includes(roleName)) {
    return next('/')
  }

  // Verifica autenticação
  if (to.meta.requiresAuth && !authService.isAuthenticated()) {
    return next('/')
  }

  // Permite a navegação para a rota desejada
  next()
})

export default router
